import React from "react";

import { FB, Twitter, LinkedIn } from "../media/Icons";
const Footer = () => {
  const organizations = [
    "https://www.wstraining.co.uk/img/DCE.png",
    "https://www.wstraining.co.uk/img/esf.jpg",
    "https://www.wstraining.co.uk/img/SFA.png",
    "https://www.wstraining.co.uk/img/app.png",
    "https://www.wstraining.co.uk/img/matrix.png",
  ];
  const navigations = [
    {
      route: "/terms-of-use",
      title: "Terms of Use",
    },
    {
      route: "/terms-of-service",
      title: "Terms of Service",
    },
    {
      route: "/privacy-policy",
      title: "Privacy Policy",
    },
    {
      route: "/accessibility",
      title: "Accessibility Statement",
    },
    {
      route: "/equality-diversity",
      title: "Equality & Diversity",
    },
    {
      route: "/covid-19-policy.pdf",
      title: "COVID-19 Policy",
    },
    {
      route: "/safeguarding",
      title: "Safeguarding",
    },
    {
      route: "/jobs",
      title: "Jobs",
    },
    {
      route: "/17-1-Sub-Contractor-Management-PolicyV7-website-version.pdf",
      title: "Supply Chain Management",
    },
    {
      route: "/customer-compliaints-policy-and-procedure-v7-june-2019.pdf",
      title: "Customer Complaints Policy",
    },
    {
      route: "/Whistleblowing%20Policy%20Jan%202018.pdf",
      title: "Whistleblowing Policy",
    },
    {
      route: "/sitemap",
      title: "Sitemap",
    },
  ];
  return (
    <div className="pt-12">
      <div className="bg-footertop py-12 px-5">
        <div className="content max-w-6xl mx-auto w-full">
          <div className="text-xl md:text-3xl text-primary text-center">
            WS Training is affiliated and associated with the following
            organisations:
          </div>
          <div className="flex w-full  mt-12 px-12 flex-wrap">
            {organizations.map((organization) => {
              return (
                <div
                  key={organization}
                  className="w-1/2 md:w-1/5 pr-4 flex justify-center mb-3 md:mb-0"
                >
                  <img style={{ maxHeight: "75px" }} src={organization} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="bg-footerbottom py-8 px-5">
        <div className="max-w-6xl mx-auto content">
          <div className="text-white">
            <ul className="flex justify-center flex-wrap text-lg">
              <a
                href="https://www.facebook.com/wstrainingcentral/"
                target="_blank"
                rel="noopener"
              >
                <li className="mr-5 w-full md:w-auto flex">
                  <FB width="15px" />{" "}
                  <span className="ml-1">@wstrainingcentral</span>
                </li>
              </a>
              <a
                href="https://twitter.com/wstrainingltd"
                target="_blank"
                rel="noopener"
              >
                <li className="mr-5 w-full md:w-auto flex">
                  {" "}
                  <Twitter width="15px" />
                  <span className="ml-1">@WSTraingLtd</span>
                </li>
              </a>
              <a
                href="https://www.linkedin.com/company/ws-training-ltd/"
                target="_blank"
                rel="noopener"
              >
                <li className="mr-5 w-full md:w-auto flex">
                  <LinkedIn width="15px" />
                  <span className="ml-1">ws-training-ltd</span>
                </li>
              </a>
            </ul>
          </div>
          <div className="text-white mt-4">
            <ul className="flex flex-wrap justify-start md:justify-center">
              {navigations.map((navigation) => {
                return (
                  <a
                    key={`${navigation.route}_${navigation.title}`}
                    href={`https://www.wstraining.co.uk${navigation.route}`}
                    target="_blank"
                    rel="noopener"
                    className="w-1/2 md:w-auto"
                  >
                    {" "}
                    <li className="pr-4 md:pr-0 md:mr-12 mb-2 md:mb-4 text-lg ">
                      {navigation.title}
                    </li>
                  </a>
                );
              })}
            </ul>
          </div>
          <div className="mt-8">
            <div className="text-lg text-white text-center">
              © 2018 WS Training Ltd, Manor Barn, Church Road, Great Barton,
              Bury St Edmunds, Suffolk, IP31 2QR
            </div>
            <div className="mt-4 text-center text-white opacity-75">
              Designed By{" "}
              <a
                href="https://elementsoftworks.co.uk/"
                target="_blank"
                rel="noopener"
              >
                Elements Softworks Limited
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
