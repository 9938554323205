import PropTypes from "prop-types";
import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faChevronLeft as faSolidChevronLeft,
  faChevronRight as faSolidChevronRight,
  faPen as faSolidPen,
  faTrash as faSolidTrash,
} from "@fortawesome/pro-solid-svg-icons";

import Header from "./header";
import Footer from "./footer";

library.add(faSolidChevronLeft, faSolidChevronRight, faSolidPen, faSolidTrash);

function Layout({
  children,
  completedPercentage,
  currentStep,
  currentStepIndex,
  started,
  onStart,
}) {
  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header
        completedPercentage={completedPercentage}
        currentStep={currentStep}
        started={started}
        onStart={onStart}
      />

      <main>{children}</main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
