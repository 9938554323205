import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import Img from "gatsby-image";
function Header({ completedPercentage, currentStep, started, onStart }) {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site, Logo, Flower } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      Logo: allFile(filter: { absolutePath: { regex: "/logo.png/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      Flower: allFile(filter: { absolutePath: { regex: "/flower.png/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const logo = Logo.edges[0].node;
  const flower = Flower.edges[0].node;

  return (
    <header className="relative">
      <div className="absolute left-0 top-0 flower">
        <Img fluid={flower.childImageSharp.fluid}></Img>
      </div>
      <div className="py-4 bg-primary">
        <div className="max-w-6xl mx-auto flex justify-end px-5">
          {" "}
          <a
            href="https://www.wstraining.co.uk/"
            rel="noopener"
            target="_blank"
          >
            {" "}
            <button className="text-white">Back to main site</button>
          </a>
        </div>
      </div>
      <div className=" py-2 bg-white">
        <div className="max-w-6xl mx-auto flex justify-between px-5">
          <div className="w-1/3 md:w-1/5">
            <Img fluid={logo.childImageSharp.fluid} />
          </div>
          {!started && (
            <button
              onClick={() => {
                onStart();
              }}
              className="text-black font-medium"
            >
              Start Appliction Form
            </button>
          )}
        </div>
      </div>
      <div className="background-top relative">
        <div className="section absolute bottom-0  text-white w-full ">
          <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between py-4 md:py-12 px-5">
            <h2 className="text-xl md:text-3xl font-bold">
              {started ? `${completedPercentage}% Complete` : "Introduction"}
            </h2>
            <h2 className="text-xl md:text-3xl">Application Portal</h2>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
