import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />

      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#101014"
      />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff" />
      {/* Primary Meta Tags */}
      <title>WS Training Application Portal</title>
      <meta name="title" content="WS Training Application Portal" />
      <meta
        name="description"
        content="East Anglia’s premier training provider. Offering you Apprenticeships, A full range of commercial courses, Study Programmes, Hairdressing training and other advice & guidance."
      />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://ws-training-portal.netlify.app/"
      />
      <meta property="og:title" content="WS Training Application Portal" />
      <meta
        property="og:description"
        content="East Anglia’s premier training provider. Offering you Apprenticeships, A full range of commercial courses, Study Programmes, Hairdressing training and other advice & guidance."
      />
      <meta
        property="og:image"
        content="https://ws-training-portal.netlify.app/preview.png"
      />
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content="https://ws-training-portal.netlify.app/"
      />
      <meta property="twitter:title" content="WS Training Application Portal" />
      <meta
        property="twitter:description"
        content="East Anglia’s premier training provider. Offering you Apprenticeships, A full range of commercial courses, Study Programmes, Hairdressing training and other advice & guidance."
      />
      <meta
        property="twitter:image"
        content="https://ws-training-portal.netlify.app/preview.png"
      />
      <html lang="en" />
      <link rel="canonical" href="https://ws-training-portal.netlify.app/" />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default SEO;
